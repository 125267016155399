import React from 'react'
import Slider from '../../components/slider'
import connectData from './connect-data'
import edgeData from './edge-data'

const ProfilesPage = () => {
  return (
    <section className='section'>
      <h1 className='title is-3 has-text-danger-dark'>Комплектующие</h1>
      <div className='columns'>
        <div className='column is-5' style={{ height: '400px' }}>
          <Slider data={connectData} />
        </div>
        <div className='column content'>
          <p className='mb-3'>
            <span className='tag is-success mr-1'>Соединяет листы</span>
            <span className='tag is-primary mr-1'>УФ-защита</span>
          </p>
          <p>
            Для соединения листов поликарбоната между собой используются два типа профилей: разъёмный и неразъёмный.   Длина таких профилей – 6000 мм.
          </p>
          <p>
            <b>Разъёмный профиль</b> состоит из базы и крышки. Сначала база монтируется на конструкцию, после чего на неё заводятся листы. Когда листы установлены, можно защёлкивать верхнюю крышку. Обратите внимание – защёлкнуть крышку нужно на самые нижние зубцы базы, верхние зубцы – подгоночные.
          </p>
          <p>
            Разъёмный профиль <b>удобен в работе</b> и позволяет легко соединять листы на арочных конструкциях. Конструкция базы предусматривает отвод влаги, что позволяет избежать протечек.
          </p>
          <p>
            <b>Неразъёмный профиль</b> лучше применять для вертикального остекления (перегородки, окна ангаров, стены теплиц). Такой профиль стоит дешевле, но на арочной конструкции его пазы сужаются и работать с ним становится сложнее. А вот для ленточного остекления этот профиль прекрасно подходит.
          </p>
        </div>
      </div>
      <div className='columns'>
        <div className='column content'>
          <p className='mb-3'>
            <span className='tag is-success mr-1'>Защищает от пыли</span>
            <span className='tag is-primary mr-1'>УФ-защита</span>
          </p>
          <p>
            Торцы сотовых листов следует закрывать от попадания пыли и влаги. Для этой цели служат торцевые и F-образные профили.
          </p>
          <p>
            <b>Торцевой профиль</b> выпускается индивидуально на каждую толщину, чтобы плотно прилегать к листу. Предварительно следует проклеить торец специальной лентой, после чего следует надеть торцевой профиль на кромку. Длина торцевого профиля – 2100 мм.
          </p>
          <p>
            <b>F-образные профили</b> служат для примыкания к стене или углу конструкции. Кромка листа вставляется в паз, а сам профиль фиксируется в точке примыкания. Длина профиля – 6000 мм.
          </p>
          <p>
            Важно помнить, что торцевые и F-образные профили практически не гнутся в силу своей конструкции. Это следует учесть при проектировке.
          </p>
          <p>
            Также мы поставляем коньковые и угловые профили, для аккуратной окантовки сложных конструкций.
          </p>
        </div>
        <div className='column is-5' style={{ height: '400px' }}>
          <Slider data={edgeData} />
        </div>
      </div>
    </section>
  )
}

export default ProfilesPage

