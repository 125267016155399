import React, { useState } from 'react'
import MenuItems from '../helpers/menu-items'

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onClick = () => {
    setIsOpen((isOpen) => {
      return !isOpen
    })
  }

  const menuClass = isOpen ? 'navbar-menu is-active' : 'navbar-menu '

  return (
    <div className='container'>
      <nav className='navbar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          <a className='navbar-item' href='https://lexan15.ru'>
            <h1 className='title is-2 has-text-danger-dark'>ЛЕКСАН</h1>
          </a>

          <button className='button is-white navbar-burger burger lx-menu-button' aria-label='menu' aria-expanded='false'
            data-target='navbarBasicExample'
            onClick={onClick}>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </button>
        </div>

        <div id='navbarBasicExample' className={menuClass}>
          <div className='navbar-start'>

          </div>

          <div className='navbar-end'>
            <MenuItems itemClass='navbar-item' isList={false} />
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Menu