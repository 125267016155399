import React from 'react'

const ControlButton = ({ isActive, clickHandler, direction, children }) => {
  if (!isActive) return null

  const buttonClass = (direction > 0) ? 'slider__button_right' : 'slider__button_left'
  return (
    <button className={`slider__button ${buttonClass}`} onClick={() => clickHandler(direction)}>
      {children}
    </button>
  )
}

ControlButton.defaultProps = {
  isVisible: true,
  clickHandler: () => { },
  direction: 1,
}

export default ControlButton