const multiwallData = [
  {
    alt: 'Бронзовый',
    url: 'images/multiwall-slider/pc_bronze.jpg'
  },
  {
    alt: 'Синий',
    url: 'images/multiwall-slider/pc_blue.jpg'
  },
  {
    alt: 'Прозрачный',
    url: 'images/multiwall-slider/pc_clear.jpg'
  },
  {
    alt: 'Бирюзовый',
    url: 'images/multiwall-slider/pc_trq.jpg'
  },
  {
    alt: 'Белый',
    url: 'images/multiwall-slider/pc_white.jpg'
  },
  {
    alt: 'Зеленый',
    url: 'images/multiwall-slider/pc_green.jpg'
  },
  {
    alt: 'Серебро',
    url: 'images/multiwall-slider/pc_silver.jpg'
  },
]

export default multiwallData