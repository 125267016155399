const appsData = [
  {
    alt: 'Навес у входа',
    url: 'images/apps-slider/0.jpg'
  },
  {
    alt: 'Въезд во двор',
    url: 'images/apps-slider/1.jpg'
  },
  {
    alt: 'Перекрытие бассейна',
    url: 'images/apps-slider/2.jpg'
  },
  {
    alt: 'Козырек',
    url: 'images/apps-slider/3.jpg'
  },
  {
    alt: 'Автомобильный навес',
    url: 'images/apps-slider/4.jpg'
  },
]

export default appsData