import React from 'react'
import { NavLink } from 'react-router-dom'
import routesList from '../../routes/routes-list'

const MenuItems = ({ itemClass, isList = false }) => {
  return routesList.map(({ url, label }) => {
    // Avoiding 404 route
    if (url === '') return null
    // If it is not 404 -- we can add it to menu
    const componentBody = <NavLink exact to={url} className={itemClass}>{label}</NavLink>
    const Component = isList ?
      <li key={url}>{componentBody}</li> : <React.Fragment key={url}>{componentBody}</React.Fragment>
    return Component
  })
}

export default MenuItems