import React from 'react'

const ContactsPage = () => {
  return (
    <section className='section'>
      <h1 className='title is-3 has-text-danger-dark'>Контакты и схема проезда</h1>
      <div className='columns'>
        <div className='column is-half content'>
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <iframe src="https://yandex.ru/map-widget/v1/-/CCQ~5JbM3D" width="560" height="400" frameborder="1" allowfullscreen="true" style={{ position: 'relative' }} title='Схема проезда'>
            </iframe>
          </div>
        </div>
        <div className='column is-half content'>
          <p className='is-size-4 has-text-grey'>
            Адрес: <br /><span className='has-text-weight-semibold has-text-link-dark'>Владикавказ, ул. Хаджи Мамсурова, 60</span>
          </p>
          <p className='is-size-4 has-text-grey'>
            Телефоны: <br /><a href='tel:+78672513737' className='has-text-weight-semibold has-text-link-dark'>
              +7 (8672) 51-37-37
              </a>
            <br />
            <a href='+79288615402' className='has-text-weight-semibold has-text-link-dark'>
              +7 (928) 861-54-02
            </a>
          </p>
          <p className='is-size-5'>Время работы:</p>
          <ul className='is-size-5 m-0' style={{ listStyleType: 'none' }}>
            <li>Понедельник – Пятница: <span className='has-text-weight-semibold'>с 9.00 до 18.00</span></li>
            <li>Суббота: <span className='has-text-weight-semibold'>с 9.00 до 17.00</span></li>
            <li>Воскресенье: <span className='has-text-weight-semibold'>выходной</span></li>
          </ul>

        </div>
      </div>

    </section>
  )
}

export default ContactsPage

