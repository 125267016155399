import React, { useState, useEffect } from 'react'
import Image from './image'

const ImageContainer = ({ data, index }) => {
  const [images, setImages] = useState([])
  useEffect(() => {
    setImages((images) => {
      let id = 0;

      return data.map(({ url, alt }, idx) => {
        id++
        let imageClassToRender = 'slider__image'

        if (idx < index) {
          imageClassToRender = 'slider__image slider__image_left'
        }

        if (idx > index) {
          imageClassToRender = 'slider__image slider__image_right'
        }

        return (
          <Image
            url={url}
            alt={alt}
            key={id}
            imageClass={imageClassToRender}
          />
        )
      })
    })
  }, [index, data])

  if (!data) {
    return null
  }


  return (
    <>
      {images}
    </>)
}

export default ImageContainer