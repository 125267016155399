const solidData = [
  {
    alt: 'Прозрачность',
    url: 'images/solid-slider/solid-bronze.jpg'
  },
  {
    alt: 'Ассортимент',
    url: 'images/solid-slider/solid-color.jpg'
  },
  {
    alt: 'Чистота',
    url: 'images/solid-slider/solid-samples.jpg'
  },
  {
    alt: 'Входная группа',
    url: 'images/solid-slider/solid-entrance.jpg'
  },
  {
    alt: 'Остекление',
    url: 'images/solid-slider/solid-roof.jpg'
  },
]

export default solidData