import React from 'react'
import Slider from '../../components/slider'
import solidData from './solid-data'

const SolidPage = () => {
  return (
    <section className='section'>
      <h1 className='title is-3 has-text-danger-dark'>Монолитный поликарбонат Monogal</h1>
      <div className='columns'>
        <div className='column is-5' style={{ height: '430px' }}>
          <Slider data={solidData} />
        </div>
        <div className='column content'>
          <p className='mb-3'>
            <span className='tag is-success mr-1'>Гарантия 15 лет</span>
            <span className='tag is-info mr-1'>Служит более 20 лет</span>
            <span className='tag is-primary mr-1'>УФ-защита c двух сторон</span>
          </p>
          <p>Листовой полимер инженерного класса. Выдерживает <b>экстремальные температуры и нагрузки</b>, в том числе ударные. Монолитный поликарбонат имеет низкое водопоглощение и и теплопроводность. Это трудногорючий материал со степенью горючести Г-3.</p>
          <p>Монолитный поликарбонат обладает колоссальной прочностью, прозрачностью стекла при малом весе (по сравнению с силикатным стеклом). Монолитный поликарбонат Monogal защищён от ультрафиолетового излучения с двух сторон, поэтому не важно какой к солнцу его устанавливать.</p>
          <p>Для производства поликарбоната Monogal оборудована <b>уникальная</b> (единственная на данный момент в России) <b>чистая комната</b>, которая обеспечивает строгий режим влажности и отсутствия статического электричества в зоне экструзии, что гарантирует оптическую чистоту пластика и отсутствие пыли и прочих посторонних частиц на поверхности листа.</p>
          <p>Стандартный размер листа 2050х3050 мм и 2050х6000 мм.</p>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-one-third'>
          <div className='notification is-primary is-light box content'>
            <h3 className='title is-size-4'>Преимущества</h3>
            <p>
              Материал с повышенной устойчивостью к природным и механическим воздействиям. Гнётся без термообработки. Навес из монолитного поликарбоната надёжно защитит от града ваш двор и автомобиль.
            </p>
            <p>
              Этот полимер прост в обработке и монтаже. Возможна поставка листов нестандартной длины под размеры вашей конструкции.
            </p>
          </div>
        </div>
        <div className='column is-one-third'>
          <div className='notification is-info is-light box content'>
            <h3 className='title is-size-4'>Область применения</h3>
            <ul className='m-4'>
              <li>
                Элитные навесы повышенной прочности
              </li>
              <li>
                Защитные экраны для продавцов в магазинах
              </li>
              <li>
                Зенитные фонари, перегородки
              </li>
              <li>
                Теплицы, зимние сады, сельское хозяйство
              </li>
              <li>
                Шумозащитные экраны
              </li>
              <li>
                Дизайн интерьера
              </li>
              <li>
                Светотехника
              </li>
              <li>
                Реклама
              </li>
            </ul>
          </div>
        </div>
        <div className='column is-one-third'>
          <div className='notification box content'>
            <h3 className='title is-size-4'>Сертификаты и инструкции</h3>
            <ul className='m-4'>
              <li>
                <a href='files/monogal.pdf' target='_blank'>Загрузить брошюру с описанем продукта</a>
              </li>
              <li>
                <a href='files/monogal-fire.pdf' target='_blank'>Сертификат пожарной безопасности Monogal</a>
              </li>
              <li>
                <a href='files/monogal-sootv.pdf' target='_blank'>Сертификат соответствия Monogal</a>
              </li>
              <li>
                <a href='files/monogal-install.pdf' target='_blank'>Инструкция по монтажу</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <table
        className='table is-fullwidth is-striped is-hoverable has-text-primary-dark has-background-primary-light has-text-centered mt-3 is-size-7-mobile'>
        <caption className='is-size-5'><strong className='has-text-grey-dark'>Технические характеристики</strong></caption>
        <thead className='has-background-primary-dark'>
          <tr>
            <th className='has-text-primary-light has-text-left'>Толщина (мм)</th>
            <th className='has-text-primary-light'>3</th>
            <th className='has-text-primary-light'>4</th>
            <th className='has-text-primary-light'>5</th>
            <th className='has-text-primary-light'>6</th>
            <th className='has-text-primary-light'>8</th>
            <th className='has-text-primary-light'>10</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='has-text-left'>Вес (кг/м&sup2;)</td>
            <td>3.60</td>
            <td>4.80</td>
            <td>6.00</td>
            <td>7.20</td>
            <td>9.60</td>
            <td>12.00</td>
          </tr>
          <tr>
            <td className='has-text-left'>Мин. радиус изгиба (м)</td>
            <td>0.60</td>
            <td>0.80</td>
            <td>1.00</td>
            <td>1.20</td>
            <td>1.60</td>
            <td>2.00</td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default SolidPage

