import React from 'react'
import { Link } from 'react-router-dom'

const MainPage = () => {
  return (
    <>
      <section className='section'>
        <div className='columns'>
          <div className='column'>
            <h1 className='title has-text-danger-dark'>Проверено временем</h1>
          </div>
          <div className='column is-three-quarters is-size-5'>
            С 1997 года мы работаем на рынке Северной Осетии — Алании.
            Магазин «Лексан» первым в республике начал поставлять качественный поликарбонат для навесов и теплиц.
            За более чем 20 лет мы заслужили репутацию надежного партнера как у профессиональных монтажников, так и у частных
            покупателей.
            </div>
        </div>
      </section>
      <section className='section'>
        <div className='columns'>
          <div className='column is-one-third'>
            <Link to='/multiwall'>
              <div className='card has-background-primary-dark'>
                <div className='card-image'>
                  <figure className='image is-square'>
                    <img src='/images/main/pcss.jpg' alt='Сотовый поликарбонат' />
                  </figure>
                </div>
                <div className='card-content'>
                  <p className='content has-text-white'>
                    <strong>Сотовый поликарбонат</strong> израильской компании <strong>Polygal</strong>. Гарантийный срок 15 лет и опыт производства более 40 лет — залог долговечности вашего навеса.
                </p>
                </div>
              </div>
            </Link>
          </div>
          <div className='column is-one-third'>
            <Link to='/solid'>
              <div className='card has-background-primary-dark'>
                <div className='card-image'>
                  <figure className='image  is-square'>
                    <img src='/images/main/solid.jpg' alt='Монолитный поликарбонат' />
                  </figure>
                </div>
                <div className='card-content'>
                  <p className='content has-text-white'>
                    <strong>Монолитный поликарбонат</strong> — уникальный материал, который сочетает в себе прочность стали и малый вес пластика. Для элитных навесов повышенной прочности.
                </p>
                </div>
              </div>
            </Link>
          </div>
          <div className='column is-one-third'>
            <article className='message is-primary'>
              <div className='message-header has-background-primary-dark'>
                <p>О магазине</p>
              </div>
              <div className='message-body'>
                <div className='content'>
                  <ul className='m-2' >
                    <li>Профессионально занимаемся поставками в РСО — Алания <strong>более 20 лет</strong>.</li>
                    <li>Продаем вам такой же поликарбонат, который покупаем для своего дома. Ценим <strong>качество и традиции</strong>.
                      </li>
                    <li>На складе всегда <strong>более 30.000</strong> квадратных метров поликарбоната различных толщин и цветов. Хватит всем!
                      </li>
                    <li>Сотовый поликарбонат толщиной 4, 6 и 8 мм возможно приобрести <strong>погонными метрами</strong>.
                      </li>
                    <li>Возможна поставка листов <strong>индивидуальных размеров</strong> под заказ.</li>
                    <li>В наличии монолитный поликарбонат <strong>длиной 6000 мм</strong>!</li>
                  </ul>
                </div>
              </div>
            </article>

          </div>
        </div>
      </section>
    </>
  )
}

export default MainPage

