import React from 'react'

const Image = ({ url, alt, imageClass }) => {
  return (<img src={url} alt={alt} className={imageClass} />)
}

Image.defaultProps = {
  alt: 'Some baeutiful picture',
}

export default Image