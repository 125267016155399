const edgeData = [
  {
    alt: 'Торцевой',
    url: 'images/edge-slider/up-bronze.jpg'
  },
  {
    alt: 'Торцевой',
    url: 'images/edge-slider/up-blue.jpg'
  },
  {
    alt: 'Торцевой',
    url: 'images/edge-slider/up-clear.jpg'
  },
  {
    alt: 'F-образный',
    url: 'images/edge-slider/f-bronze.jpg'
  },
  {
    alt: 'F-образный',
    url: 'images/edge-slider/f-clear.jpg'
  },
]

export default edgeData