import React from 'react'
import './index.css'

const Banner = () => {
  return (
    <section className='hero has-background-danger-dark'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column'>
              <h1 className='title has-text-white-ter'>
                Качественный поликарбонат Polygal</h1>
              <h2 className='subtitle has-text-white-ter'>
                в наличии в магазине «Лексан»
              </h2>
            </div>
            <div className='column has-text-right'>
              <h2 className='subtitle has-text-white-ter'>
                Владикавказ, ул. Хаджи Мамсурова, 60
              </h2>
              <h1 className='title has-text-white-ter'>
                <a href='tel:+78672513737' className='has-text-white'>+7 (8672) 51-37-37</a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner