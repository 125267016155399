import React from 'react'

const Marker = ({ data, index }) => {
  let key = 0
  const markers = data.map((item, idx) => {
    key++
    const bulletClass = (index === idx) ? 'slider__marker_active' : 'slider__marker'
    return <div className={bulletClass} key={key}></div>
  })
  return (
    <div className='slider__markers-line'>
      {markers}
    </div>
  )
}

export default Marker