const connectData = [
  {
    alt: 'Разъемный',
    url: 'images/connect-slider/hcp-bronze.jpg'
  },
  {
    alt: 'Разъемный',
    url: 'images/connect-slider/hcp-blue.jpg'
  },
  {
    alt: 'Разъемный',
    url: 'images/connect-slider/hcp-clear.jpg'
  },
  {
    alt: 'Разъемный',
    url: 'images/connect-slider/hcp-green.jpg'
  },
  {
    alt: 'Неразъемный',
    url: 'images/connect-slider/hp-bronze.jpg'
  },
  {
    alt: 'Неразъемный',
    url: 'images/connect-slider/hp-clear.jpg'
  },
]

export default connectData