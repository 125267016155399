import { MainPage, SolidPage, MultiwallPage, ProfilesPage, ContactsPage } from '../pages'

const baseTitle = 'Магазин Лексан —'
const routesList = [
  { label: 'Главная', url: '/', Component: MainPage, title: `${baseTitle} Главная страница` },
  { label: 'Сотовый поликарбонат', url: '/multiwall', Component: MultiwallPage, title: `${baseTitle} Сотовый поликарбонат Polygal купить во Владикавказе` },
  { label: 'Монолитный поликарбонат', url: '/solid', Component: SolidPage, title: `${baseTitle} Монолитный поликарбонат Monogal купить во Владикавказе` },
  { label: 'Комплектующие', url: '/profiles', Component: ProfilesPage, title: `${baseTitle} Комплектующие для поликарбоната` },
  { label: 'Контакты', url: '/contacts', Component: ContactsPage, title: `${baseTitle} Контакты и схема проезда` },
  { label: 'Главная', url: '', Component: MainPage, title: `${baseTitle} Главная страница` },
]

export default routesList