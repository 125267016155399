import React from 'react'
import Slider from '../../components/slider'
import multiwallData from './multiwall-data'
import appsData from './apps-data'

const MultiwallPage = () => {
  return (
    <>
      <section className='section'>
        <h1 className='title is-3 has-text-danger-dark'>Сотовый поликарбонат Polygal</h1>
        <div className='columns'>
          <div className='column is-5' style={{ height: '430px' }}>
            <Slider data={multiwallData} />
          </div>
          <div className='column content'>
            <p className='mb-3'>
              <span className='tag is-success mr-1'>Гарантия 15 лет</span>
              <span className='tag is-info mr-1'>Служит более 20 лет</span>
              <span className='tag is-primary mr-1'>УФ-защита</span>
            </p>
            <p>
              «Полигаль Стандарт» – это сотовый поликарбонат, характеристики которого были <b>заложены в российский ГОСТ</b>. Рецептура изготовления корректировалась и проверялась на практике в течение нескольких десятков лет, поэтому удельный вес этих листов можно смело назвать «классическим».
            </p>
            <p>
              Израильская группа компаний Plazit-Polygal производит полимерные листы уже <b>более 40 лет</b>. За это долгое время были выработаны жёсткие стандарты контроля качества продукции. Каждый час на производственной линии осуществляются контрольные замеры, которые фиксируются в журнале. На каждый лист наносится номер экструдера и дата и время производства – это делается для того, что можно было оперативно осуществлять гарантийное сопровождение.
            </p>
            <p>
              Каждый поликарбонатный лист «Полигаль Стандарт» или «Полигаль Практичный» защищён от ультрафиолетового изучения. Специальный <b>защитный слой</b> вплавляется в поверхность листа во время производства. Это обеспечивает долговечность материала и его устойчивость к агрессивному солнечному излучению.
            </p>
            <p>
              Стандартный размер листа – 2100х12000 мм.
            </p>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-one-third'>
            <div className='notification is-primary is-light box content'>
              <h3 className='title is-size-4'>Преимущества</h3>
              <p>
                Листы из поликарбоната, при своём малом весе, очень прочные и их практически невозможно разбить. Структура листа обеспечивает высокие теплоизоляционные свойства, что особенно ценно при строительстве теплиц и зимних садов.
            </p>
              <p>
                Особенно следует отметить устойчивость к природному воздействию – ветру, снег у и граду. Диапазон критических температур от -40 &deg;С до +120 &deg;С.
            </p>
            </div>
          </div>
          <div className='column is-one-third'>
            <div className='notification is-info is-light box content'>
              <h3 className='title is-size-4'>Область применения</h3>
              <ul className='m-4'>
                <li>
                  Теплицы, зимние сады, сельское хозяйство
              </li>
                <li>
                  Навесы, зенитные фонари, перегородки
              </li>
                <li>
                  Шумозащитные экраны
              </li>
                <li>
                  Дизайн интерьера
              </li>
                <li>
                  Светотехника
              </li>
                <li>
                  Реклама
              </li>
              </ul>
            </div>
          </div>
          <div className='column is-one-third'>
            <div className='notification box content'>
              <h3 className='title is-size-4'>Сертификаты и инструкции</h3>
              <ul className='m-4'>
                <li>
                  <a href='files/multiwall-cat.pdf' target='_blank'>Загрузить брошюру с описанем продукта</a>
                </li>
                <li>
                  <a href='files/multiwall-fire.pdf' target='_blank'>Сертификат пожарной безопасности для «Стандарт» и «Практичный»</a>
                </li>
                <li>
                  <a href='files/multiwall-sootv.pdf' target='_blank'>Сертификат соответствия для «Стандарт» и «Практичный»</a>
                </li>
                <li>
                  <a href='files/multiwall-install.pdf' target='_blank'>Инструкция по монтажу</a>
                </li>
                <li>
                  <a href='files/multiwall-quality.pdf' target='_blank'>Качество продукции</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <table
              className='table is-fullwidth is-striped is-hoverable has-text-primary-dark has-background-primary-light has-text-centered mt-3 is-size-7-mobile'>
              <caption className='is-size-5'><strong className='has-text-grey-dark'>Полигаль Стандарт ГОСТ</strong></caption>
              <thead className='has-background-primary-dark'>
                <tr>
                  <th className='has-text-primary-light has-text-left'>Толщина</th>
                  <th className='has-text-primary-light'>4 мм</th>
                  <th className='has-text-primary-light'>6 мм</th>
                  <th className='has-text-primary-light'>8 мм</th>
                  <th className='has-text-primary-light'>10 мм</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='has-text-left'>Вес (г/м&sup2;)</td>
                  <td>800</td>
                  <td>1300</td>
                  <td>1500</td>
                  <td>1700</td>
                </tr>
                <tr>
                  <td className='has-text-left'>Ширина (мм)</td>
                  <td>2100</td>
                  <td>2100</td>
                  <td>2100</td>
                  <td>2100</td>
                </tr>
                <tr>
                  <td className='has-text-left'>Минимальный радиус изгиба (мм)</td>
                  <td>700</td>
                  <td>1050</td>
                  <td>1400</td>
                  <td>1750</td>
                </tr>
                <tr>
                  <td className='has-text-left'>Сопротивление теплопередаче R (м&sup2;&sdot;&deg;C/Вт)</td>
                  <td>0.256</td>
                  <td>0.278</td>
                  <td>0.303</td>
                  <td>0.330</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='column is-half'>
            <table
              className='table is-fullwidth is-striped is-hoverable has-text-primary-dark has-background-primary-light has-text-centered mt-3 is-size-7-mobile'>
              <caption className='is-size-5'><strong className='has-text-grey-dark'>Полигаль Практичный</strong></caption>
              <thead className='has-background-primary-dark'>
                <tr>
                  <th className='has-text-primary-light has-text-left'>Толщина</th>
                  <th className='has-text-primary-light'>4 мм</th>
                  <th className='has-text-primary-light'>6 мм</th>
                  <th className='has-text-primary-light'>8 мм</th>
                  <th className='has-text-primary-light'>10 мм</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='has-text-left'>Вес (г/м<sup>2</sup>)</td>
                  <td>650</td>
                  <td>1100</td>
                  <td>1300</td>
                  <td>1450</td>
                </tr>
                <tr>
                  <td className='has-text-left'>Ширина (мм)</td>
                  <td>2100</td>
                  <td>2100</td>
                  <td>2100</td>
                  <td>2100</td>
                </tr>
                <tr>
                  <td className='has-text-left'>Минимальный радиус изгиба (мм)</td>
                  <td>800</td>
                  <td>1200</td>
                  <td>1500</td>
                  <td>1900</td>
                </tr>
                <tr>
                  <td className='has-text-left'>Сопротивление теплопередаче R (м&sup2;&sdot;&deg;C/Вт)</td>
                  <td>0.256</td>
                  <td>0.278</td>
                  <td>0.303</td>
                  <td>0.330</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className='columns'>
          <div className='column content'>
            <h1 className='title has-text-danger-dark'>Покупайте в магазине «Лексан»</h1>
            <p>
              Наш магазин является авторизованным реселлером продукции Polygal на территории РСО—Алания. «Лексан» осуществляет гарантийную поддержку завода-производителя.
            </p>
            <p>
              Также мы оказываем следующие услуги:
            </p>
            <ul>
              <li>поставка листов нестандартных размеров</li>
              <li>консультации по монтажу</li>
              <li>бесплатный раскрой</li>
              <li>доставка на объект по звонку</li>
              <li>послепродажная гарантийная поддержка</li>
            </ul>
          </div>
          <div className='column is-6' style={{ height: '430px' }}>
            <Slider data={appsData} />
          </div>
        </div>
      </section>
    </>
  )
}

export default MultiwallPage

