import React, { useState } from 'react'

import ImageContainer from './image-container'
import Marker from './marker'
import ControlButton from './control-button'

import './index.css'

const Slider = ({ data }) => {

  const [index, setIndex] = useState(0)

  if (!data) return null

  const totalImages = data.length - 1

  const onClick = (direction) => {
    setIndex((index) => {
      const newIndex = index + direction
      if (newIndex < 0) return 0
      if (newIndex > totalImages) return totalImages
      return newIndex
    })
  }

  const caption = data[index].alt

  return (
    <div className='slider'>
      <ImageContainer data={data} index={index} />
      <ControlButton
        direction={-1}
        clickHandler={onClick}
        isActive={(index !== 0) ? true : false}
      >
        &laquo;
      </ControlButton>
      <ControlButton
        direction={1}
        clickHandler={onClick}
        isActive={(index !== totalImages) ? true : false}
      >
        &raquo;
      </ControlButton>
      <h3 className='slider__caption'>{caption}</h3>
      <Marker data={data} index={index} />
    </div>
  )
}

Slider.dafaultProps = {
  data: undefined
}

export default Slider