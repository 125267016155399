import React from 'react'
import MenuItems from '../helpers/menu-items'

const Footer = () => {
  const year = (new Date()).getFullYear()
  return (
    <footer className='footer has-background-info-light'>
      <div className='content container has-text-left'>
        <div className='columns'>
          <div className='column'>
            <h4 className='is-4 m-0'>Магазин «Лексан»</h4>
            <p>Поставки поликарбоната Polygal во
            Владикавказе</p>
            <p>&copy; 1997 — {year}</p>
          </div>

          <div className='column is-one-quarter'>
            <ul className='m-0 p-0 has-text-weight-bold' style={{ listStyleType: 'none' }}>
              <MenuItems itemClass='' isList={true} />
            </ul>
          </div>
          <div className='column is-one-quarter'>
            <p>Владикавказ, ул. Хаджи Мамсурова, 60</p>
            <h3 className='title m-0'>
              <a href='tel:+78672513737'>+7 (8672) 51-37-37</a>
            </h3>
          </div>
        </div>
      </div>
    </footer >
  )
}

export default Footer