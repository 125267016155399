import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Menu from '../menu'
import Banner from '../banner'
import Footer from '../footer'
import Routes from '../../routes'

import './index.css'

const App = () => {
  return (
    <>
      <Router>
        <Menu />
        <Banner />

        <Routes />

        <Footer />
      </Router>
    </>
  )
}

export default App