import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Title from '../components/title'
import routesList from './routes-list'

const Routes = () => {
  const routesArr = routesList.map(({ url, Component, title }) => {
    return (
      <Route path={url} key={url} exact render={(props) => (
        <Title title={title}>
          <Component />
        </Title>
      )} />
    )
  })
  return (
    <div className='container'>
      <Switch>
        {routesArr}
      </Switch>
    </div>
  )
}

export default Routes